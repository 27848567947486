@tailwind base;
@tailwind components;
@tailwind utilities;

    
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*
{
    /* border: 1px solid white; */
    user-select: none;
}

body
{
    background-color: #333333;
    font-family: "Open Sans", sans-serif;
    margin-top: 22vh;

}

#skeletCard
  {
    width: 25vw;
  }

@media screen and (min-width: 768px)
{
   #ListeOfPage
   {
         margin-right: 10%;
   }

   #Card1
   {
        width: 80%;
   }
   
   
  

#ListeOfPage a {
    margin-right: 2em;
    transition: 0.5s ;
}
#ListeOfPage a:hover {
    color :  #E5F15E !important; 
}

}


  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .skeleton {
    background-color: #a7a7a7;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
  }
  
  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
  

 
  @media screen and (max-width: 768px)
{
  #skeletCard
  {
    width: 90vw;
  }

}



/* styles.css */
.description-limited {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.2em; /* Adjust based on line height */
}

.show-more {
  color: #E5F15E; /* Adjust color as needed */
  cursor: pointer;
  display: inline-block;
  margin-top: 0.5em;
}
